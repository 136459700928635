<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Invoices
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                :itemsPerPage="15"
                border
                :loading="isLoading"
                :items="normalizedInvoices"
                :fields="columns">

              <template #loading>
                <CElementCover
                    :boundaries="[
                      { sides: ['top'], query: 'td' },
                      { sides: ['bottom'], query: 'tbody' }
                    ]"
                >
                  <h1 class="d-inline">Loading... </h1><CSpinner size="5xl" color="info"/>
                </CElementCover>
              </template>

              <template #action="{item}">
                <td style="text-align: center">
                  <template v-if="item.is_convertible">
                    <a href="#" @click.prevent="downloadInvoice(item.id, 'usd')">USD</a>&nbsp;
                    <a href="#" @click.prevent="downloadInvoice(item.id, 'eur')">EUR</a>
                  </template>
                  <template v-else>
                    <a href="#" @click.prevent="downloadInvoice(item.id)"><CIcon name="cil-cloud-download"/></a>
                  </template>
                </td>
              </template>
            </CDataTable>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import InvoiceService from "../services/InvoiceService";
import {Auth} from "aws-amplify";

export default {
  name: 'Invoices',
  components: {},
  data() {
    return {
      isLoading: false,
      columns: [
        {key: 'id', label: 'ID'},
        {key: 'date', label: 'Date'},
        {key: 'amount', label: 'Amount'},
        {key: 'action', label: 'Download', _style: 'text-align: center;'},
      ],
      invoices: [],

      // If not empty - admin
      networkId: ''
    }
  },
  methods: {
    async fetchInvoices() {
      try {
        this.isLoading = true;

        const response = await InvoiceService.getInvoices(this.networkId);

        this.invoices = response.data;

      } finally {
        this.isLoading = false;
      }
    },
    async downloadInvoice(id, currency) {
      try {
        this.isLoading = true;

        const response = await InvoiceService.getInvoices(this.networkId, id, currency);

        let a = document.createElement("a"); //Create <a>
        a.href = "data:application/pdf;base64," + response.data.body; //Image Base64 Goes here
        a.download = `invoice_${id}.pdf`; // File name here

        document.body.appendChild(a);
        a.click(); //Downloaded file

      } catch (error) {
        const {message} = error.response.data;

        if (message === 'Please enter your billing data on the Profile page and try again.') {

          let query = '';
          if(this.networkId) query = `?network_id=${this.networkId}`;

          await this.$router.push(`/profile${query}`);
        }
      } finally {
        this.isLoading = false;
      }
    }
  },
  computed: {
    normalizedInvoices() {
      return this.invoices.map(item => {
        return {
          id: item.id,
          date: dayjs.unix(item.at).format('MMM DD, YYYY'),
          amount: item.amount_ || '-',
          is_convertible: item.is_convertible,
        }
      });
    }
  },
  async mounted() {
    const userSessionData = await Auth.currentAuthenticatedUser();
    const userGroups = userSessionData.signInUserSession.idToken.payload['cognito:groups'] || [];

    if(userGroups.includes('admin')) {
      if(this.$route.query.network_id) {

        this.networkId = this.$route.query.network_id;
      } else {
        this.$toast.error('Wrong network_id')
        return false;
      }
    }

    await this.fetchInvoices()
  }
}
</script>
