import {Auth} from "aws-amplify";
import {AbstractService} from "@/services/AbstractService";

export default new class UserService extends AbstractService {

	async getInvoices(networkId, id, currency) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		const params = {};

		if (currency) params.currency = currency;
		if (networkId) params.network_id = networkId;
		if (id) params.id = id;

		return this.api.get('/invoice',
			{
				params,
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}
}
